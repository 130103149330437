// Styles
import { useState } from 'react'
import styles from './Loading.module.scss'

function Loading({ delay }) {
  const [visible, setVisible] = useState(false)

  useState(() => {
    // Delay showing the loader until a specific number of ms have passed
    setTimeout(() => {
      setVisible(true)
    }, delay)
  }, [])

  if (!visible) return

  return (
    <div className={styles.loading}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

Loading.defaultProps = {
  delay: 0,
}

export default Loading
