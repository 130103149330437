import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {
    name: null,
    insta_handle: null,
    avatar_url: null,
    public_url: null,
    bg_url: null,
    buttons: [],
    social: [],
  },
}

const linkInBioSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
      updateData: (state, { payload }) => {
        state.data = {
          ...state.data,
          ...payload.data,
        }
      },
    },
  }),
  actions = linkInBioSlice.actions

export { linkInBioSlice, actions }
