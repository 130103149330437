import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Helpers
import { trackPageView, trackSession } from 'helpers/metrics'

function Analytics() {
  const history = useHistory()
  const company_id = useSelector(state => state.common.company_id)

  useEffect(() => {
    if (!company_id) return
    trackPageView(window.location.pathname)
    trackSession()
  }, [company_id])

  useEffect(() => {
    if (!company_id) return

    return history.listen(location => {
      trackPageView(location.pathname)
    })
  }, [history, company_id])

  return <></>
}

export default Analytics
