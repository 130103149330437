export const getClubSubdomain = () => {
  const host = window.location.host

  if (host.includes('localhost') && process.env.REACT_APP_SUBDOMAIN) {
    return process.env.REACT_APP_SUBDOMAIN
  }

  const subdomain = host.split('.')[0]
  if (host === subdomain) return null
  return subdomain
}

export const isReducedMotionEnabled = () => {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

  if (!mediaQuery || mediaQuery.matches) {
    return true
  }
  return false
}

export const getLang = subdomain => {
  switch (subdomain) {
    case 'omarapollo':
    case 'sacrepublicfc':
    case 'wearecollide':
      return 'en'
    default:
      return 'fr'
  }
}
