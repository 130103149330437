import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store'

import App from './App'
import ScrollToTop from 'components/scroll-to-top/ScrollToTop'
import Loading from 'components/loading/Loading'

import './i18n'
import './index.scss'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ScrollToTop />
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>
)
