import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'react-simple-snackbar'

// Actions
import { clearError } from 'actions/common'

function ErrorDialog() {
  const [openSnackbar] = useSnackbar({
    position: 'top-center',
    style: {
      backgroundColor: 'red',
      color: 'white',
    },
  })
  const { error } = useSelector(state => state.common)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!error) return

    let isLoggedOut =
      (error?.includes('code 401') || error?.includes('code=400')) ?? false

    if (!isLoggedOut) {
      openSnackbar(error)
    }

    return () => {
      dispatch(clearError())
    }
  }, [error, dispatch, openSnackbar])

  return <div />
}

export default ErrorDialog
