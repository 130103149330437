import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: null,
  is_authenticated: localStorage.getItem('token') !== null,
  show_auth_modal: false,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      updateState: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      signOut: state => {
        state.data = null
        state.is_authenticated = false
      },
    },
  }),
  actions = userSlice.actions

export { userSlice, actions }
