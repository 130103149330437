import axios from 'axios'

import { BACKEND_URL } from 'consts'
import { getClubSubdomain } from 'helpers/common'
import { actions } from 'reducers/common'

export const getSubdomain = () => dispatch => {
  dispatch(
    actions.updateState({
      key: 'subdomain',
      value: getClubSubdomain(),
    })
  )
}

export const getCompanyID = () => dispatch => {
  const companyId = localStorage.getItem('companyId')
  if (companyId) {
    return dispatch(
      actions.updateState({
        key: 'company_id',
        value: parseInt(companyId),
      })
    )
  }
  axios
    .get(BACKEND_URL + '/company')
    .then(resp => {
      localStorage.setItem('companyId', parseInt(resp.data.company_id))
      dispatch(
        actions.updateState({
          key: 'company_id',
          value: parseInt(resp.data.company_id),
        })
      )
    })
    .catch(err => {
      // TODO: Temp toString() because the error respnse is malformed (not serializable)
      dispatch(actions.updateError(err.toString()))
    })
}

export const setTitle = title => dispatch => {
  dispatch(
    actions.updateState({
      key: 'title',
      value: title,
    })
  )
}

export const clearError = () => dispatch => {
  dispatch(actions.clearError())
}
