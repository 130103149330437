import mobile from 'is-mobile'
import axios from 'axios'
import { ANALYTICS_URL } from 'consts'

export const trackMetric = (label, e, type = 'click') => {
  const body = {
    company_id: parseInt(localStorage.getItem('companyId')),
    metric: type,
    metric_details: label,
    metric_origin: 'fan',
    count: 1,
  }

  if (process.env.NODE_ENV === 'production') {
    axios.post(`${ANALYTICS_URL}/metric`, body)
  }

  if (e) {
    const link = e.target?.href ? e.target : e.target.parentElement
    const url = link.href

    if (isExternalLink(url)) {
      e.preventDefault()

      // Delay navigation so our tracking call completes
      setTimeout(() => {
        if (link.target === '_blank') {
          window.open(url, '_blank')
        } else {
          document.location = url
        }
      }, 150)
    }
  }
}

export const trackPageView = path => {
  const body = {
    company_id: parseInt(localStorage.getItem('companyId')),
    metric: 'page_view',
    metric_details: path,
    metric_origin: 'fan',
    count: 1,
    data: {
      device: mobile() ? 'mobile' : 'desktop',
      origin: document.referrer,
    },
  }

  if (process.env.NODE_ENV === 'production') {
    axios.post(`${ANALYTICS_URL}/metric`, body)
  }
}

export const trackSession = () => {
  const body = {
    company_id: parseInt(localStorage.getItem('companyId')),
    metric: 'session',
    metric_details: localStorage.visited ? 'returning' : 'new',
    metric_origin: 'fan',
    count: 1,
  }

  if (!sessionStorage.session) {
    sessionStorage.session = true

    if (process.env.NODE_ENV === 'production') {
      axios.post(`${ANALYTICS_URL}/metric`, body)
    }
  }

  if (!localStorage.visitor) {
    localStorage.visited = true
  }
}

const isExternalLink = url => {
  const tmp = document.createElement('a')
  tmp.href = url
  return tmp.host !== window.location.host
}
