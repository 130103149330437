import { lazy } from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import axios from 'axios'
import SnackbarProvider from 'react-simple-snackbar'

// Components
import Analytics from 'components/analytics/Analytics'
import ErrorDialog from 'components/error-dialog/ErrorDialog'

// Lazy Loaded
const LinkInBio = lazy(() => import('views/link-in-bio/LinkInBio'))
const Contests = lazy(() => import('views/contests/Contests'))

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
  'token'
)}`

function App() {
  return (
    <>
      <Analytics />

      <SnackbarProvider>
        <Switch>
          <Route path="/" exact component={LinkInBio} />
          <Route path="/contests" component={Contests} />
        </Switch>

        <ErrorDialog />
      </SnackbarProvider>
    </>
  )
}

export default withRouter(App)
