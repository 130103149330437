import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  contest: null,
  hasMore: true,
  is_file_selected: null,
  loading: false,
  upload: {
    chunks: null,
    progress: null,
  },
}

const contestsSlice = createSlice({
    name: 'contests',
    initialState,
    reducers: {
      updateState: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      updateContests: (state, action) => {
        state.data = action.payload
      },
      updateContestMedia: (state, action) => {
        state.contest.contributions.media = action.payload
      },
      updateLoading: (state, { payload }) => {
        state.loading = payload
      },
      updateUploadChunks: (state, { payload }) => {
        state.upload.chunks = payload
      },
      incrementUploadProgress: state => {
        state.upload.progress = state.upload.progress + 1
      },
      resetUpload: state => {
        state.upload.chunks = null
        state.upload.progress = null
      },
      resetContest: state => {
        state.contest = null
      },
    },
  }),
  actions = contestsSlice.actions

export { contestsSlice, actions }
