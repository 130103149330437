import { combineReducers } from 'redux'
import { commonSlice } from 'reducers/common'
import { linkInBioSlice } from 'reducers/link-in-bio'
import { contestsSlice } from 'reducers/contests'
import { userSlice } from 'reducers/user'

export const rootReducer = () =>
  combineReducers({
    common: commonSlice.reducer,
    linkInBio: linkInBioSlice.reducer,
    contests: contestsSlice.reducer,
    user: userSlice.reducer,
  })
