import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  subdomain: null,
  loading: true,
  error: null,
  company_id: null,
  title: 'Contests',
  is_new: false,
  flags: {
    auth: false,
    sms: false,
    instagram: false,
    navigation: false,
  },
}

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
      updateState: (state, { payload }) => {
        state[payload.key] = payload.value
      },
      updateLoading: (state, { payload }) => {
        state.loading = payload
      },
      updateError: (state, { payload }) => {
        console.error(payload)
        state.error = payload
        state.loading = false
      },
      clearError: state => {
        state.error = null
      },
    },
  }),
  actions = commonSlice.actions

export { commonSlice, actions }
